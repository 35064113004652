.PagePresences {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	gap: var(--size-gap-half);
	.Rest {
		display: flex;
		align-items: center;
		gap: 2px;
	}
}
