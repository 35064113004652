@use '~/src/lib/mixins';

* {
	position: relative;
	box-sizing: border-box;
}
:root {
	--gap: 1.5rem;
}
:root {
	--app-height: 100vh;
	--app-width: 100vw;
}

@include mixins.mobile {
	:root {
		--gap: 5vw;
	}
}
html {
	display: flex;
	flex: 1;
	flex-direction: column;
}
html {
	margin: 0;
	padding: 0;
	min-height: 100%;
}
body {
	display: flex;
	flex-direction: column;
	margin: 0;
	background: var(--color-white);
	padding: 0;
	font: var(--font-body);
}
input,
button,
select,
textarea {
	font: var(--font-body-m);
}
body {
	grid-template-rows: auto 1fr auto;
	flex: 1;
	background-color: var(--color-white);
	color: var(--color-black);
	accent-color: var(--color-black);
}
#root {
	display: flex;
	flex: 1;
	flex-direction: column;
}
.content {
	display: grid;
	grid-template-rows: 1fr;
	grid-template-columns:
		[full-start] 1fr [content-start] min(calc(var(--app-width) - var(--size-gap-2x) * 2), var(--size-content-width))
		[content-end] 1fr [full-end];
	grid-column: full;
	padding: 0 var(--size-gap);
}

@include mixins.mobile {
	.content {
		grid-template-columns: [full-start content-start] 1fr [content-end full-end];
	}
}

.content-dark {
	background-color: var(--color-brand-primary);
}
@include mixins.desktop {
	.content {
		grid-template-columns: [full-start] 1fr [content-start] var(--size-content-width) [content-end] 1fr [full-end];
	}
}
a.inherit {
	color: inherit;
	text-decoration: none;
}
img {
	display: block;
	max-width: 100%;
}
button {
	cursor: pointer;
	outline: none;
	box-shadow: none;
	border: 0;
	font-size: inherit;
}
button[disabled] {
	cursor: default;
}
label {
	font: var(--font-body-sb);
}
label:hover {
	cursor: inherit;
}
hr {
	margin: var(--size-gap-half) 0;
	outline: none;
	border: none;
	background: var(--color-light-gray);
	width: 100%;
	height: 1px;
}
.Row {
	@include mixins.row;
}
.Center {
	@include mixins.center-column;
}
.AbsoluteFill {
	@include mixins.absolute-fill;
}
strong {
	font: var(--font-body-sb);
}
