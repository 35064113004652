@use '~/src/lib/mixins.scss';

.Header {
	display: flex;
	position: sticky;
	top: 0;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	z-index: 1001;
	background: var(--color-true-black);
	padding: 1rem;
	height: 56px;
	@include mixins.mobile {
		position: sticky;
	}

	.LogoContainer {
		display: flex;
		flex-direction: row;
		align-items: center;

		.MenuIcon {
			display: none;
			top: -1px;
			margin-right: 1rem;
			width: 24px;
			color: var(--color-white);
			font-size: 20px;
			&:hover {
				cursor: pointer;
			}
			@include mixins.mobile {
				display: block;
			}
		}
		img {
			width: 124px;
		}
	}
	.UserContainer {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: var(--size-gap);
		.Logout {
			color: var(--color-offwhite);
			font-size: 14px;
			&:hover {
				cursor: pointer;
			}
		}
	}
	.SocketStatus {
		border-radius: 50%;
		background-color: var(--color-gray);
		width: 8px;
		height: 8px;
		&.Open {
			background-color: var(--color-green);
		}
		&.Connecting {
			background-color: var(--color-yellow);
		}
		&.Closed {
			background-color: var(--color-red);
		}
	}
}
