@use '~/src/lib/mixins';

.Layout {
	display: flex;
	grid-column: full;
	flex: 1;
	flex-direction: column;
	align-items: stretch;
	background: var(--color-white);
	max-width: var(--app-width);

	> .ContentContainer {
		display: flex;
		grid-column: full;
		flex-grow: 1;
		flex-direction: row;
		padding-left: var(--size-gap);
		max-width: var(--size-content-width);
		@include mixins.mobile {
			padding-left: 0;
		}
		@include mixins.desktop {
			padding-left: 0;
			max-width: var(--size-large-content-width);
		}
	}
}
