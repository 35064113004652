.Button {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: var(--size-gap-quarter);
	transition: all 0.1s ease;
	border-radius: 1000px; /* Stupid hack for pill shape */
	background-color: var(--color-dark-gray);
	padding: var(--size-gap-half) var(--size-gap-2x);
	overflow: hidden;
	color: var(--color-white);
	font: var(--font-body-l-b);
	white-space: nowrap;
	&:hover {
		background: var(--color-gray);
	}
	&[disabled] {
		background-color: var(--color-offwhite);
	}
	&[disabled]:hover {
		cursor: default;
		background: var(--color-offwhite);
	}
	&.Secondary {
		border: 1px solid var(--color-light-gray);
		border-radius: 4px;
		background-color: var(--color-white);
		padding: var(--size-gap-quarter) var(--size-gap);
		color: var(--color-black);
		font: var(--font-body-sb);
		line-height: 1;
		&[disabled] {
			color: var(--color-gray);
		}
		&:hover {
			background: var(--color-light-gray);
		}
		&:hover[disabled] {
			background-color: var(--color-white);
		}
	}
	&.Tertiary {
		display: inline-block;
		background: transparent;
		color: var(--color-black);
		font: var(--font-body);
		font-size: 14px;
		&[disabled] {
			color: var(--color-gray);
		}
		&[disabled]:hover {
			cursor: default;
		}
		&:hover {
			text-decoration: underline;
		}
	}
}
