@use '~/src/lib/mixins';

.Nav {
	position: absolute;
	left: var(--size-gap);
	flex-shrink: 0;
	transform: translateX(calc(-100%));
	z-index: 1000;
	transition: all 0.3s ease;
	background: var(--color-dark-gray);
	width: 250px;
	min-height: 100%;
	@include mixins.mobile {
		left: 0;
		transform: translateX(-100%);
		width: 100vw;
		.Active .Create {
			opacity: 1;
		}
	}
	@include mixins.desktop {
		position: relative;
		left: 0;
		transform: translateX(0);
		.Active .Create {
			opacity: 1;
		}
	}
	&.Visible {
		left: 0;
		transform: translateX(0);
		.Active .Create {
			opacity: 1;
		}
	}
	> div {
		position: sticky;
		top: calc(56px + var(--size-gap-half));
		@include mixins.mobile {
			position: inherit;
			top: 0;
		}

		a {
			color: var(--color-white);
			text-decoration: none;
		}

		h2 {
			margin: var(--size-gap-half) var(--size-gap);
			font: var(--font-body-l-b);
		}
		ul {
			display: none;
			margin: 0;
			padding: 0;
			&.Visible {
				display: block;
			}
			li {
				margin: 0;
				padding-right: var(--size-gap);
				padding-left: var(--size-gap);
				list-style: none;
				// font-weight: ${location.pathname.slice(1).split('/').shift().includes(page.page) ? 700 : 500};
				&:not(.Section):hover {
					background: var(--color-black);
					@include mixins.mobile {
						background: inherit;
					}
					.Create {
						opacity: 1;
					}
				}
				&.Section {
					margin: var(--size-gap-half) 0 var(--size-gap-quarter);
					padding-left: var(--size-gap-2x);
					color: var(--color-light-gray);
					font: var(--font-body);
					text-transform: uppercase;
				}

				a {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: var(--size-gap-quarter) var(--size-gap);
					color: var(--color-offwhite);
					font: var(--font-body-m);
					text-decoration: none;
					> div {
						display: flex;
						flex-direction: row;
						align-items: center;
						gap: var(--size-gap-half);
					}
					img {
						width: 14px;
						height: 1em;
					}
				}
				&.Active {
					a {
						color: var(--color-white);
						font: var(--font-body-m-sb);
					}
					.Create {
						transition: all 0.3s ease;
					}
				}
				.Create {
					position: absolute;
					top: 50%;
					right: var(--size-gap-half);
					transform: translateY(-50%);
					opacity: 0;
					width: 14px;
				}
			}
		}
	}
}
