

:root {
  --color-brand-primary: #141923;
  --color-brand-primary-dark: #0c111b;
  --color-brand-primary-modal: #141923fa;
  --color-brand-primary-overlay: #141923b3;
  --color-brand-secondary: #ff6e40;
  --color-brand-link: #0fb5a7;
  --color-offblack: #333;
  --color-black: #1e1e1e;
  --color-true-black: #000;
  --color-white: #fff;
  --color-offwhite: #efefef;
  --color-dark-gray: #373737;
  --color-gray: #878787;
  --color-light-gray: #ccc;
  --color-green: #1feb9e;
  --color-red: #ff5549;
  --color-blue: #128dff;
  --color-yellow: #fff354;
  --color-purple: #7d5dfc;
  --color-desaturated-white-border: #fff3;
  --color-desaturated-white-text: #fff6;
  --color-desaturated-white-background: #fffc;
  --color-desaturated-black-border: #0000001a;
  --color-desaturated-black-text: #0006;
  --color-transparent-black: #0000004d;
  --color-shadow: #00000040;
  --color-inner-shadow: #0000000d;
  --font-body: 400 14px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-sb: 600 14px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-b: 700 14px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-s: 400 12px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-s-sb: 600 12px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-s-b: 700 12px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-m: 400 16px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-m-sb: 600 16px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-m-b: 700 16px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-l: 400 18px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-l-sb: 600 18px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-l-b: 700 18px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-xl: 400 24px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-xl-sb: 600 24px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-xl-b: 700 24px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-highlight: oswald, helvetica neue, helvetica, sans-serif;
  --font-mono-s: 12px source code pro, monospace;
  --font-mono-s-b: 700 12px source code pro, monospace;
  --font-mono: 14px source code pro, monospace;
  --font-mono-b: 700 14px source code pro, monospace;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: var(--row-gap);
  --size-content-width: 1400px;
  --size-large-content-width: 1720px;
  --size-mobile-width: 640px;
  --size-tablet-width: 1024px;
  --size-laptop-width: 1440px;
  --size-desktop-width: 1440px;
  --size-large-desktop-width: 1740px;
}

* {
  box-sizing: border-box;
  position: relative;
}

:root {
  --gap: 1.5rem;
  --app-height: 100vh;
  --app-width: 100vw;
}

@media (width <= 640px) {
  :root {
    --gap: 5vw;
  }
}

html {
  flex-direction: column;
  flex: 1;
  min-height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
}

body {
  background: var(--color-white);
  font: var(--font-body);
  flex-direction: column;
  margin: 0;
  padding: 0;
  display: flex;
}

input, button, select, textarea {
  font: var(--font-body-m);
}

body {
  background-color: var(--color-white);
  color: var(--color-black);
  accent-color: var(--color-black);
  flex: 1;
  grid-template-rows: auto 1fr auto;
}

#root {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.content {
  grid-template-rows: 1fr;
  grid-template-columns: [full-start] 1fr [content-start] min(var(--app-width)  - var(--size-gap-2x) * 2, var(--size-content-width)) [content-end] 1fr [full-end];
  padding: 0 var(--size-gap);
  grid-column: full;
  display: grid;
}

@media (width <= 640px) {
  .content {
    grid-template-columns: [full-start content-start] 1fr[content-end full-end];
  }
}

.content-dark {
  background-color: var(--color-brand-primary);
}

@media (width >= 1441px) {
  .content {
    grid-template-columns: [full-start] 1fr [content-start] var(--size-content-width) [content-end] 1fr [full-end];
  }
}

a.inherit {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
  display: block;
}

button {
  cursor: pointer;
  box-shadow: none;
  font-size: inherit;
  border: 0;
  outline: none;
}

button[disabled] {
  cursor: default;
}

label {
  font: var(--font-body-sb);
}

label:hover {
  cursor: inherit;
}

hr {
  margin: var(--size-gap-half) 0;
  background: var(--color-light-gray);
  border: none;
  outline: none;
  width: 100%;
  height: 1px;
}

.Row {
  flex-direction: row;
  align-items: center;
  gap: 0;
  display: flex;
}

.Center {
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.AbsoluteFill {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

strong {
  font: var(--font-body-sb);
}

.Button {
  justify-content: center;
  align-items: center;
  gap: var(--size-gap-quarter);
  background-color: var(--color-dark-gray);
  padding: var(--size-gap-half) var(--size-gap-2x);
  color: var(--color-white);
  font: var(--font-body-l-b);
  white-space: nowrap;
  border-radius: 1000px;
  transition: all .1s;
  display: flex;
  overflow: hidden;
}

.Button:hover {
  background: var(--color-gray);
}

.Button[disabled] {
  background-color: var(--color-offwhite);
}

.Button[disabled]:hover {
  cursor: default;
  background: var(--color-offwhite);
}

.Button.Secondary {
  border: 1px solid var(--color-light-gray);
  background-color: var(--color-white);
  padding: var(--size-gap-quarter) var(--size-gap);
  color: var(--color-black);
  font: var(--font-body-sb);
  border-radius: 4px;
  line-height: 1;
}

.Button.Secondary[disabled] {
  color: var(--color-gray);
}

.Button.Secondary:hover {
  background: var(--color-light-gray);
}

.Button.Secondary:hover[disabled] {
  background-color: var(--color-white);
}

.Button.Tertiary {
  color: var(--color-black);
  font: var(--font-body);
  background: none;
  font-size: 14px;
  display: inline-block;
}

.Button.Tertiary[disabled] {
  color: var(--color-gray);
}

.Button.Tertiary[disabled]:hover {
  cursor: default;
}

.Button.Tertiary:hover {
  text-decoration: underline;
}

.Avatar {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: block;
}

:root {
  --color-brand-primary: #141923;
  --color-brand-primary-dark: #0c111b;
  --color-brand-primary-modal: #141923fa;
  --color-brand-primary-overlay: #141923b3;
  --color-brand-secondary: #ff6e40;
  --color-brand-link: #0fb5a7;
  --color-offblack: #333;
  --color-black: #1e1e1e;
  --color-true-black: #000;
  --color-white: #fff;
  --color-offwhite: #efefef;
  --color-dark-gray: #373737;
  --color-gray: #878787;
  --color-light-gray: #ccc;
  --color-green: #1feb9e;
  --color-red: #ff5549;
  --color-blue: #128dff;
  --color-yellow: #fff354;
  --color-purple: #7d5dfc;
  --color-desaturated-white-border: #fff3;
  --color-desaturated-white-text: #fff6;
  --color-desaturated-white-background: #fffc;
  --color-desaturated-black-border: #0000001a;
  --color-desaturated-black-text: #0006;
  --color-transparent-black: #0000004d;
  --color-shadow: #00000040;
  --color-inner-shadow: #0000000d;
  --font-body: 400 14px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-sb: 600 14px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-b: 700 14px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-s: 400 12px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-s-sb: 600 12px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-s-b: 700 12px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-m: 400 16px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-m-sb: 600 16px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-m-b: 700 16px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-l: 400 18px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-l-sb: 600 18px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-l-b: 700 18px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-xl: 400 24px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-xl-sb: 600 24px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-xl-b: 700 24px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-highlight: oswald, helvetica neue, helvetica, sans-serif;
  --font-mono-s: 12px source code pro, monospace;
  --font-mono-s-b: 700 12px source code pro, monospace;
  --font-mono: 14px source code pro, monospace;
  --font-mono-b: 700 14px source code pro, monospace;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: var(--row-gap);
  --size-content-width: 1400px;
  --size-large-content-width: 1720px;
  --size-mobile-width: 640px;
  --size-tablet-width: 1024px;
  --size-laptop-width: 1440px;
  --size-desktop-width: 1440px;
  --size-large-desktop-width: 1740px;
}

.Header {
  z-index: 1001;
  background: var(--color-true-black);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  padding: 1rem;
  display: flex;
  position: sticky;
  top: 0;
}

@media (width <= 640px) {
  .Header {
    position: sticky;
  }
}

.Header .LogoContainer {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.Header .LogoContainer .MenuIcon {
  color: var(--color-white);
  width: 24px;
  margin-right: 1rem;
  font-size: 20px;
  display: none;
  top: -1px;
}

.Header .LogoContainer .MenuIcon:hover {
  cursor: pointer;
}

@media (width <= 640px) {
  .Header .LogoContainer .MenuIcon {
    display: block;
  }
}

.Header .LogoContainer img {
  width: 124px;
}

.Header .UserContainer {
  align-items: center;
  gap: var(--size-gap);
  flex-direction: row;
  display: flex;
}

.Header .UserContainer .Logout {
  color: var(--color-offwhite);
  font-size: 14px;
}

.Header .UserContainer .Logout:hover {
  cursor: pointer;
}

.Header .SocketStatus {
  background-color: var(--color-gray);
  border-radius: 50%;
  width: 8px;
  height: 8px;
}

.Header .SocketStatus.Open {
  background-color: var(--color-green);
}

.Header .SocketStatus.Connecting {
  background-color: var(--color-yellow);
}

.Header .SocketStatus.Closed {
  background-color: var(--color-red);
}

:root {
  --color-brand-primary: #141923;
  --color-brand-primary-dark: #0c111b;
  --color-brand-primary-modal: #141923fa;
  --color-brand-primary-overlay: #141923b3;
  --color-brand-secondary: #ff6e40;
  --color-brand-link: #0fb5a7;
  --color-offblack: #333;
  --color-black: #1e1e1e;
  --color-true-black: #000;
  --color-white: #fff;
  --color-offwhite: #efefef;
  --color-dark-gray: #373737;
  --color-gray: #878787;
  --color-light-gray: #ccc;
  --color-green: #1feb9e;
  --color-red: #ff5549;
  --color-blue: #128dff;
  --color-yellow: #fff354;
  --color-purple: #7d5dfc;
  --color-desaturated-white-border: #fff3;
  --color-desaturated-white-text: #fff6;
  --color-desaturated-white-background: #fffc;
  --color-desaturated-black-border: #0000001a;
  --color-desaturated-black-text: #0006;
  --color-transparent-black: #0000004d;
  --color-shadow: #00000040;
  --color-inner-shadow: #0000000d;
  --font-body: 400 14px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-sb: 600 14px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-b: 700 14px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-s: 400 12px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-s-sb: 600 12px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-s-b: 700 12px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-m: 400 16px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-m-sb: 600 16px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-m-b: 700 16px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-l: 400 18px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-l-sb: 600 18px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-l-b: 700 18px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-xl: 400 24px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-xl-sb: 600 24px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-xl-b: 700 24px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-highlight: oswald, helvetica neue, helvetica, sans-serif;
  --font-mono-s: 12px source code pro, monospace;
  --font-mono-s-b: 700 12px source code pro, monospace;
  --font-mono: 14px source code pro, monospace;
  --font-mono-b: 700 14px source code pro, monospace;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: var(--row-gap);
  --size-content-width: 1400px;
  --size-large-content-width: 1720px;
  --size-mobile-width: 640px;
  --size-tablet-width: 1024px;
  --size-laptop-width: 1440px;
  --size-desktop-width: 1440px;
  --size-large-desktop-width: 1740px;
}

.Nav {
  left: var(--size-gap);
  z-index: 1000;
  background: var(--color-dark-gray);
  flex-shrink: 0;
  width: 250px;
  min-height: 100%;
  transition: all .3s;
  position: absolute;
  transform: translateX(-100%);
}

@media (width <= 640px) {
  .Nav {
    width: 100vw;
    left: 0;
    transform: translateX(-100%);
  }

  .Nav .Active .Create {
    opacity: 1;
  }
}

@media (width >= 1441px) {
  .Nav {
    position: relative;
    left: 0;
    transform: translateX(0);
  }

  .Nav .Active .Create {
    opacity: 1;
  }
}

.Nav.Visible {
  left: 0;
  transform: translateX(0);
}

.Nav.Visible .Active .Create {
  opacity: 1;
}

.Nav > div {
  top: calc(56px + var(--size-gap-half));
  position: sticky;
}

@media (width <= 640px) {
  .Nav > div {
    position: inherit;
    top: 0;
  }
}

.Nav > div a {
  color: var(--color-white);
  text-decoration: none;
}

.Nav > div h2 {
  margin: var(--size-gap-half) var(--size-gap);
  font: var(--font-body-l-b);
}

.Nav > div ul {
  margin: 0;
  padding: 0;
  display: none;
}

.Nav > div ul.Visible {
  display: block;
}

.Nav > div ul li {
  padding-right: var(--size-gap);
  padding-left: var(--size-gap);
  margin: 0;
  list-style: none;
}

.Nav > div ul li:not(.Section):hover {
  background: var(--color-black);
}

@media (width <= 640px) {
  .Nav > div ul li:not(.Section):hover {
    background: inherit;
  }
}

.Nav > div ul li:not(.Section):hover .Create {
  opacity: 1;
}

.Nav > div ul li.Section {
  margin: var(--size-gap-half) 0 var(--size-gap-quarter);
  padding-left: var(--size-gap-2x);
  color: var(--color-light-gray);
  font: var(--font-body);
  text-transform: uppercase;
}

.Nav > div ul li a {
  padding: var(--size-gap-quarter) var(--size-gap);
  color: var(--color-offwhite);
  font: var(--font-body-m);
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.Nav > div ul li a > div {
  align-items: center;
  gap: var(--size-gap-half);
  flex-direction: row;
  display: flex;
}

.Nav > div ul li a img {
  width: 14px;
  height: 1em;
}

.Nav > div ul li.Active a {
  color: var(--color-white);
  font: var(--font-body-m-sb);
}

.Nav > div ul li.Active .Create {
  transition: all .3s;
}

.Nav > div ul li .Create {
  top: 50%;
  right: var(--size-gap-half);
  opacity: 0;
  width: 14px;
  position: absolute;
  transform: translateY(-50%);
}



:root {
  --color-brand-primary: #141923;
  --color-brand-primary-dark: #0c111b;
  --color-brand-primary-modal: #141923fa;
  --color-brand-primary-overlay: #141923b3;
  --color-brand-secondary: #ff6e40;
  --color-brand-link: #0fb5a7;
  --color-offblack: #333;
  --color-black: #1e1e1e;
  --color-true-black: #000;
  --color-white: #fff;
  --color-offwhite: #efefef;
  --color-dark-gray: #373737;
  --color-gray: #878787;
  --color-light-gray: #ccc;
  --color-green: #1feb9e;
  --color-red: #ff5549;
  --color-blue: #128dff;
  --color-yellow: #fff354;
  --color-purple: #7d5dfc;
  --color-desaturated-white-border: #fff3;
  --color-desaturated-white-text: #fff6;
  --color-desaturated-white-background: #fffc;
  --color-desaturated-black-border: #0000001a;
  --color-desaturated-black-text: #0006;
  --color-transparent-black: #0000004d;
  --color-shadow: #00000040;
  --color-inner-shadow: #0000000d;
  --font-body: 400 14px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-sb: 600 14px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-b: 700 14px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-s: 400 12px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-s-sb: 600 12px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-s-b: 700 12px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-m: 400 16px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-m-sb: 600 16px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-m-b: 700 16px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-l: 400 18px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-l-sb: 600 18px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-l-b: 700 18px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-xl: 400 24px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-xl-sb: 600 24px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-xl-b: 700 24px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-highlight: oswald, helvetica neue, helvetica, sans-serif;
  --font-mono-s: 12px source code pro, monospace;
  --font-mono-s-b: 700 12px source code pro, monospace;
  --font-mono: 14px source code pro, monospace;
  --font-mono-b: 700 14px source code pro, monospace;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: var(--row-gap);
  --size-content-width: 1400px;
  --size-large-content-width: 1720px;
  --size-mobile-width: 640px;
  --size-tablet-width: 1024px;
  --size-laptop-width: 1440px;
  --size-desktop-width: 1440px;
  --size-large-desktop-width: 1740px;
}

.Layout {
  background: var(--color-white);
  max-width: var(--app-width);
  flex-direction: column;
  flex: 1;
  grid-column: full;
  align-items: stretch;
  display: flex;
}

.Layout > .ContentContainer {
  padding-left: var(--size-gap);
  max-width: var(--size-content-width);
  flex-direction: row;
  flex-grow: 1;
  grid-column: full;
  display: flex;
}

@media (width <= 640px) {
  .Layout > .ContentContainer {
    padding-left: 0;
  }
}

@media (width >= 1441px) {
  .Layout > .ContentContainer {
    max-width: var(--size-large-content-width);
    padding-left: 0;
  }
}

.PageHeader {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.PagePresences {
  justify-content: flex-end;
  align-items: center;
  gap: var(--size-gap-half);
  flex-direction: row;
  display: flex;
}

.PagePresences .Rest {
  align-items: center;
  gap: 2px;
  display: flex;
}

.Picture {
  width: 100%;
}

.Picture picture, .Picture img {
  width: 100%;
  display: block;
}

:root {
  --color-brand-primary: #141923;
  --color-brand-primary-dark: #0c111b;
  --color-brand-primary-modal: #141923fa;
  --color-brand-primary-overlay: #141923b3;
  --color-brand-secondary: #ff6e40;
  --color-brand-link: #0fb5a7;
  --color-offblack: #333;
  --color-black: #1e1e1e;
  --color-true-black: #000;
  --color-white: #fff;
  --color-offwhite: #efefef;
  --color-dark-gray: #373737;
  --color-gray: #878787;
  --color-light-gray: #ccc;
  --color-green: #1feb9e;
  --color-red: #ff5549;
  --color-blue: #128dff;
  --color-yellow: #fff354;
  --color-purple: #7d5dfc;
  --color-desaturated-white-border: #fff3;
  --color-desaturated-white-text: #fff6;
  --color-desaturated-white-background: #fffc;
  --color-desaturated-black-border: #0000001a;
  --color-desaturated-black-text: #0006;
  --color-transparent-black: #0000004d;
  --color-shadow: #00000040;
  --color-inner-shadow: #0000000d;
  --font-body: 400 14px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-sb: 600 14px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-b: 700 14px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-s: 400 12px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-s-sb: 600 12px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-s-b: 700 12px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-m: 400 16px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-m-sb: 600 16px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-m-b: 700 16px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-l: 400 18px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-l-sb: 600 18px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-l-b: 700 18px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-xl: 400 24px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-xl-sb: 600 24px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-xl-b: 700 24px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-highlight: oswald, helvetica neue, helvetica, sans-serif;
  --font-mono-s: 12px source code pro, monospace;
  --font-mono-s-b: 700 12px source code pro, monospace;
  --font-mono: 14px source code pro, monospace;
  --font-mono-b: 700 14px source code pro, monospace;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: var(--row-gap);
  --size-content-width: 1400px;
  --size-large-content-width: 1720px;
  --size-mobile-width: 640px;
  --size-tablet-width: 1024px;
  --size-laptop-width: 1440px;
  --size-desktop-width: 1440px;
  --size-large-desktop-width: 1740px;
}

.Spinner {
  justify-content: center;
  align-items: center;
  gap: var(--size-gap-half);
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

:root {
  --color-brand-primary: #141923;
  --color-brand-primary-dark: #0c111b;
  --color-brand-primary-modal: #141923fa;
  --color-brand-primary-overlay: #141923b3;
  --color-brand-secondary: #ff6e40;
  --color-brand-link: #0fb5a7;
  --color-offblack: #333;
  --color-black: #1e1e1e;
  --color-true-black: #000;
  --color-white: #fff;
  --color-offwhite: #efefef;
  --color-dark-gray: #373737;
  --color-gray: #878787;
  --color-light-gray: #ccc;
  --color-green: #1feb9e;
  --color-red: #ff5549;
  --color-blue: #128dff;
  --color-yellow: #fff354;
  --color-purple: #7d5dfc;
  --color-desaturated-white-border: #fff3;
  --color-desaturated-white-text: #fff6;
  --color-desaturated-white-background: #fffc;
  --color-desaturated-black-border: #0000001a;
  --color-desaturated-black-text: #0006;
  --color-transparent-black: #0000004d;
  --color-shadow: #00000040;
  --color-inner-shadow: #0000000d;
  --font-body: 400 14px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-sb: 600 14px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-b: 700 14px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-s: 400 12px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-s-sb: 600 12px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-s-b: 700 12px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-m: 400 16px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-m-sb: 600 16px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-m-b: 700 16px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-l: 400 18px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-l-sb: 600 18px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-l-b: 700 18px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-xl: 400 24px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-xl-sb: 600 24px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-body-xl-b: 700 24px / 1.2 source sans pro, helvetica neue, helvetica, sans-serif;
  --font-highlight: oswald, helvetica neue, helvetica, sans-serif;
  --font-mono-s: 12px source code pro, monospace;
  --font-mono-s-b: 700 12px source code pro, monospace;
  --font-mono: 14px source code pro, monospace;
  --font-mono-b: 700 14px source code pro, monospace;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: var(--row-gap);
  --size-content-width: 1400px;
  --size-large-content-width: 1720px;
  --size-mobile-width: 640px;
  --size-tablet-width: 1024px;
  --size-laptop-width: 1440px;
  --size-desktop-width: 1440px;
  --size-large-desktop-width: 1740px;
}

.Suspended {
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.SuspenseError {
  justify-content: center;
  align-items: center;
  gap: var(--size-gap-half);
  font-family: var(--font-highlight);
  flex-direction: row;
  flex-grow: 1;
  display: flex;
}

/*# sourceMappingURL=app.css.map */
