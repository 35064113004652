@use '~/src/lib/mixins';
.Suspended {
	@include mixins.center-column;
	width: 100%;
}

.SuspenseError {
	@include mixins.center-column;
	flex-direction: row;
	align-items: center;
	gap: var(--size-gap-half);
	font-family: var(--font-highlight);
}
